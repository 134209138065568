import * as dompack from "dompack";
import { getTid } from "@mod-tollium/js/gettid";

export default class CheckboxGroupField
{
  constructor(node, options)
  {
    if (!node)
      return;
    this.node = node;

    this.nodes = dompack.qSA(node, "input[type='checkbox']");
    if (!this.nodes.length)
      return;

    this.min = parseInt(this.node.dataset.whMin, 10) || 0;
    this.max = parseInt(this.node.dataset.whMax, 10) || 0;

    // Set attributes for automatic Parsley validation
    if (this.min > 0 && this.max > 0)
    {
      this.nodes[0].setAttribute("data-parsley-required", "");
      this.nodes[0].setAttribute("data-parsley-check", `[${this.min},${this.max}]`);
    }
    else if (this.min > 0)
    {
      this.nodes[0].setAttribute("data-parsley-required", "");
      this.nodes[0].setAttribute("data-parsley-mincheck", this.min);
    }
    else if (this.max > 0)
    {
      this.nodes[0].setAttribute("data-parsley-maxcheck", this.max);
    }

    // Add event listeners to set custom validity
    if (this.min || this.max)
    {
      for (let checkboxnode of this.nodes)
        checkboxnode.addEventListener("change", event => this._checkValidity(event));
      this._checkValidity(null);
    }
  }

  _checkValidity(event)
  {
    let numChecked = this.nodes.filter(node => node.checked).length;
    if (numChecked < this.min)
      this.nodes[0].setCustomValidity(getTid("publisher:site.forms.commonerrors.mincheck", this.min));
    else if (this.max > 0 && numChecked > this.max)
      this.nodes[0].setCustomValidity(getTid("publisher:site.forms.commonerrors.maxcheck", this.max));
    else
      this.nodes[0].setCustomValidity("");
  }
}
