import * as dompack from 'dompack';
import * as webharefields from './internal/webharefields';
import * as merge from './internal/merge';
import { executeSubmitInstruction } from '@mod-system/js/wh/integration';
import './internal/requiredstyles.css';
import { getTid } from "@mod-tollium/js/gettid";
import "./internal/form.lang.json";
import { reportValidity, setFieldError, setupValidator } from './internal/customvalidation.es';

const compatupload = require('@mod-system/js/compat/upload');
const anyinputselector = 'input,select,textarea,*[data-wh-form-name]';
const submitselector = 'input[type=submit],input[type=image],button[type=submit],button:not([type])';

function doValidation(field, isafter)
{
  //If we're not an 'after failure' event, stay silent if the field hasn't erred yet
  if(isafter && !field.classList.contains('wh-form__field--everfailed'))
    return;

  let form = dompack.closest(field,'form');
  if(!form || !form.propWhFormhandler)
    return;

  let formhandler = form.propWhFormhandler;
  formhandler.validate([field], {focusfailed:false});
}

function handleValidateEvent(event)
{
  doValidation(event.target,false);
}
function handleValidateAfterEvent(event)
{
  doValidation(event.target,true);
}

export default class FormBase
{
  constructor(formnode)
  {
    this.node = formnode;
    if(this.node.nodeName != 'FORM')
      throw new Error("Specified node is not a <form>"); //we want our clients to be able to assume 'this.node.elements' works

    this.elements = formnode.elements;
    if(this.node.propWhFormhandler)
      throw new Error("Specified node already has an attached form handler");
    this.node.propWhFormhandler = this;

    //Implement webhare fields extensions, eg 'now' for date fields or 'enablecomponents'
    webharefields.setup(this.node);
    //Implement page navigation
    this.node.addEventListener("click", evt => this._checkClick(evt));
    this.node.addEventListener("dompack:takefocus", evt=> this._onTakeFocus(evt), true);
    this.node.addEventListener("input", evt => this._updateConditions(evt.target), true);
    this.node.addEventListener("change", evt => this._updateConditions(evt.target), true);
    this.node.addEventListener('submit', evt => this._submit(evt, null));
    this.node.addEventListener('wh:form-dosubmit', evt => this._doSubmit(evt, null));
    this.node.addEventListener("wh:form-setfielderror", evt => this._doSetFieldError(evt));

    //Update required etc handlers
    this._updateConditions();
    //Update page navigation
    let pagestate = this._getPageState();
    this._updatePageVisibility(pagestate.pages, 0);
    this._updatePageNavigation();
  }

  setupValidation(options)
  {
    if(this._dovalidation)
      throw new Error("Validation options can only be set once");
    if(!options)
      return;

    this._dovalidation = true;
    this._curtriggerevents = [...options.triggerevents];
    this._curafterevents = [...options.triggerafterfailure];
    this._curtriggerevents.forEach(eventname => this.node.addEventListener(eventname, handleValidateEvent, true));
    this._curafterevents.forEach(eventname => this.node.addEventListener(eventname, handleValidateAfterEvent, true));
    this.node.noValidate = true;
  }

  _updateFieldGroupMessageState(field, type, getError)
  {
    let mygroup = dompack.closest(field,".wh-form__fieldgroup");
    if(!mygroup)
      return null;

    let failedfield = mygroup.querySelector(".wh-form__field--" + type); //eg. wh-form__field--error
    dompack.toggleClass(mygroup, "wh-form__fieldgroup--" + type, !!failedfield); //eg. wh-form__fieldgroup--error

    let error = (failedfield ? getError(failedfield) : null) || null;
    if(error) //mark the field has having failed at one point. we will now switch to faster updating error state
      field.classList.add('wh-form__field--everfailed');

    if(error && !(error instanceof Node))
      error = dompack.create('span', { textContent: error });

    if(!dompack.dispatchCustomEvent(mygroup, 'wh:form-displaymessage', //this is where parsley hooks in and cancels to handle the rendering of faults itself
          { bubbles: true
          , cancelable: true
          , detail: { message: error
                    , field: failedfield
                    , type: type
                    } }))
    {
      return null;
    }

    let messagenode = mygroup.querySelector(".wh-form__" + type); //either wh-form__error or wh-form__suggestion
    if(!messagenode)
    {
      if(!failedfield)
        return; //nothing to do

      let suggestionholder = dompack.closest(field,'.wh-form__fields') || mygroup;
      messagenode = dompack.create("div", { className: "wh-form__" + type });
      dompack.append(suggestionholder, messagenode);
    }

    dompack.empty(messagenode);
    if(error)
      messagenode.appendChild(error);
  }

  _updateFieldGroupErrorState(field)
  {
    this._updateFieldGroupMessageState(field, 'error', failedfield => failedfield.propWhSetFieldError || failedfield.propWhValidationError);
  }

  _updateFieldGroupSuggestionState(field)
  {
    this._updateFieldGroupMessageState(field, 'suggestion', failedfield => failedfield.propWhValidationSuggestion);
  }

  _doSetFieldError(evt)
  {
    //FIXME properly handle multiple fields in this group reporting errors
    if(!this._dovalidation)
      return;

    dompack.stop(evt);

    //if we're already in error mode, always update reporting
    if(!evt.detail.reportimmediately && !evt.target.classList.contains("wh-form__field--error"))
      return;

    this._reportFieldValidity(evt.target);
  }

  _reportFieldValidity(node)
  {
    let iserror = (node.propWhSetFieldError || node.propWhValidationError);
    dompack.toggleClass(node, "wh-form__field--error", !!iserror);

    let issuggestion = !iserror && node.propWhValidationSuggestion;
    dompack.toggleClass(node, "wh-form__field--suggestion", !!issuggestion);

    this._updateFieldGroupErrorState(node);
    this._updateFieldGroupSuggestionState(node);
    return !iserror;
  }

  //validate and submit. normal submissions should use this function, directly calling submit() skips validation and busy locking
  async validateAndSubmit(extradata)
  {
    await this._submit(null, extradata);
  }

  async _submit(evt, extradata)
  {
    //A form element's default button is the first submit button in tree order whose form owner is that form element.
    let submitter = this._submitter || this.node.querySelector(submitselector);
    this._submitter = null;

    if(dompack.debugflags.fhv)
      console.log('[fhv] received submit event, submitter:', submitter);

    let tempbutton = null;
    if(submitter)
    { //temporarily add a hidden field representing the selected button
      tempbutton = document.createElement('input');
      tempbutton.name = submitter.name;
      tempbutton.value = submitter.value;
      tempbutton.type = "hidden";
      this.node.appendChild(tempbutton);
    }

    try
    {
      if(!dompack.dispatchCustomEvent(this.node, 'wh:form-beforesubmit',{ bubbles:true, cancelable:true })) //allow parsley to hook into us
        return; //we expect parsley to invoke _doSubmit through wh:form-dosubmit

      await this._doSubmit(evt, extradata);
    }
    finally
    {
      dompack.remove(tempbutton);
    }
  }
  async _doSubmit(evt, extradata)
  {
    if(evt)
      evt.preventDefault();

    let lock = dompack.flagUIBusy({ ismodal: true, component: this.node });
    this.node.classList.add('wh-form--submitting');

    try
    {
      await this._markSubmitContext(false); //a new submit round, so clear submission state

      let validationresult = await this.validate();
      if(validationresult.valid)
        await this.submit(extradata);
    }
    finally
    {
      lock.release();
      this.node.classList.remove('wh-form--submitting');
    }
  }
  //update the .wh-form--submitcontext
  async _markSubmitContext(toset)
  {
    let submitcontext = dompack.closest(this.node, '.wh-form--submitcontext');
    if(submitcontext)
    {
      dompack.toggleClass(submitcontext, 'wh-form--submitted', toset);
      if(toset) //success
        merge.run(submitcontext, { form: await this.getFormValue() });
    }
  }


  //default submission function. eg. RPC will override this
  async submit()
  {
    this.node.submit();
  }

  _onTakeFocus(evt)
  {
    let containingpage = dompack.closest(evt.target,'.wh-form__page');
    if(containingpage && containingpage.classList.contains('wh-form__page--hidden'))
    {
      //make sure the page containing the errored component is visible
      let pagenum = dompack.qSA(this.node, '.wh-form__page').findIndex(page => page == containingpage);
      if(pagenum >= 0)
        this.gotoPage(pagenum);
    }
  }

  _checkClick(evt)
  {
    let actionnode = dompack.closest(evt.target, "*[data-wh-form-action]");
    if(!actionnode)
    {
      let submitter = dompack.closest(evt.target, submitselector);
      if(submitter)
      {
        this._submitter = submitter; //store as submitter in case a submit event actually occurs
        setTimeout(() => this._submitter = null); //but clear it as soon as event processing ends
      }
      return;
    }

    dompack.stop(evt);
    this.executeFormAction(actionnode.dataset.whFormAction);
  }

  _getPageState()
  {
    let pages = dompack.qSA(this.node, '.wh-form__page');
    let curpage = pages.findIndex(page => !page.classList.contains('wh-form__page--hidden'));
    return { pages, curpage };
  }

  _updatePageVisibility(pagelist, currentpage)
  {
    pagelist.forEach( (page,idx) =>
    {
      dompack.toggleClass(page, 'wh-form__page--hidden', idx != currentpage);
      dompack.toggleClass(page, 'wh-form__page--visible', idx == currentpage);
    });
  }

  ///Get the currently opened page (page node)
  getCurrentPage()
  {
    let state = this._getPageState();
    return state.curpage >= 0 ? state.pages[state.curpage] : null;
  }

  scrollToFormTop()
  {
    let coords = this.node.getBoundingClientRect();
    if(coords.top < 0 && coords.height >= 1)
    {
      //part of the form is scrolled out of sight
      window.scrollTo(0, window.scrollY + coords.top);
    }
  }

  async gotoPage(pageidx)
  {
    let state = this._getPageState();
    if(state.curpage == pageidx)
      return;
    if (pageidx < 0 || pageidx >= state.pages.length)
      throw new Error(`Cannot navigate to nonexisting page #${pageidx}`);

    let goingforward = pageidx > state.curpage;
    this._updatePageVisibility(state.pages, pageidx);
    if(goingforward) //only makes sense to update if we're making progress
      merge.run(state.pages[pageidx], { form: await this.getFormValue() });

    this._updatePageNavigation();

    //scroll back up
    this.scrollToFormTop();

    /* tell the page it's now visible - note that we specifically don't fire this on init, as it's very likely
       users would 'miss' the event anyway - registerHandler usually executes faster than your wh:form-pagechange
       registrations, if you wrapped those in a dompack.register */
    dompack.dispatchCustomEvent(state.pages[pageidx], "wh:form-pagechange", { bubbles: true, cancelable: false });
  }

  _getDestinationPage(pagestate, direction)
  {
    let pagenum = pagestate.curpage + direction;
    while (pagenum >= 0 && pagenum < pagestate.pages.length && pagestate.pages[pagenum].propWhFormCurrentVisible === false)
      pagenum = pagenum + direction;
    if (pagenum < 0 || pagenum >= pagestate.pages.length)
      return -1;
    return pagenum;
  }

  async executeFormAction(action)
  {
    switch(action)
    {
      case 'previous':
      {
        if(this.node.classList.contains('wh-form--allowprevious'))
          this.gotoPage(this._getDestinationPage(this._getPageState(), -1));
        return;
      }
      case 'next':
      {
        let pagestate = this._getPageState();
        if(this.node.classList.contains('wh-form--allownext'))
        {
          if( (await this.validate(pagestate.pages[pagestate.curpage])).valid)
            this.gotoPage(this._getDestinationPage(pagestate, +1));
        }
        return;
      }
      default:
      {
        console.error(`Unknown form action '${action}'`);
      }
    }
  }

  applyEnableOn(control, targets)
  {
    for (let element of control.dataset.whFormEnable.split(" "))
    {
      let target = this.node.elements[element];
      if (target)
        targets.set(element, !!control.checked || !!control.selected);
    }
  }

  async _updateConditions(target)
  {
    // Check pages visibility
    let hiddenPages = [];
    let mergeNodes = [];
    let anychanges = false;
    let targets = new Map();
    for (let formpage of dompack.qSA(this.node, ".wh-form__page"))
    {
      let visible = true;
      if (formpage.dataset.whFormVisibleIf)
      {
        visible = this._matchesCondition(formpage.dataset.whFormVisibleIf);
        if (!visible)
          hiddenPages.push(formpage); // We don't have to check fields on this page any further

        if (visible != formpage.propWhFormCurrentVisible)
        {
          anychanges = true;
          formpage.propWhFormCurrentVisible = visible;
          mergeNodes.push(formpage);
        }
      }
    }
    if (anychanges)
      this._updatePageNavigation();

    // This is the initialization, check the enable components for all elements within the form
    for (let enableoncontrol of dompack.qSA(this.node, "*[data-wh-form-enable]"))
      this.applyEnableOn(enableoncontrol, targets);

    let tovalidate = [];
    for (let formgroup of dompack.qSA(this.node, ".wh-form__fieldgroup"))
    {
      let visible = !hiddenPages.includes(dompack.closest(formgroup, ".wh-form__page"))
          && this._matchesCondition(formgroup.dataset.whFormVisibleIf);

      let enabled = visible
          && this._matchesCondition(formgroup.dataset.whFormEnabledIf);

      //load initial status?
      if(formgroup.propWhFormInitialRequired === undefined)
        formgroup.propWhFormInitialRequired = formgroup.classList.contains("wh-form__fieldgroup--required");

      let required = enabled
                     && (formgroup.dataset.whFormRequiredIf ? this._matchesCondition(formgroup.dataset.whFormRequiredIf) : formgroup.propWhFormInitialRequired);

      if (visible !== formgroup.propWhFormCurrentVisible // These are initially undefined, so this code will always run first time
          || enabled !== formgroup.propWhFormCurrentEnabled
          || required !== formgroup.propWhFormCurrentRequired)
      {
        formgroup.propWhFormCurrentVisible = visible;
        formgroup.propWhFormCurrentEnabled = enabled;
        formgroup.propWhFormCurrentRequired = required;

        dompack.toggleClass(formgroup, "wh-form__fieldgroup--hidden", !visible);
        dompack.toggleClass(formgroup, "wh-form__fieldgroup--disabled", !enabled);
        dompack.toggleClass(formgroup, "wh-form__fieldgroup--required", required);

        mergeNodes.push(formgroup);
      }

      // Look for nodes that are explicit enable event listeners, or by default and not opting out
      let enabletargets = dompack.qSA(formgroup, "[data-wh-form-enable-listener='true'],input:not([data-wh-form-enable-listener='false']),select:not([data-wh-form-enable-listener='false']),textarea:not([data-wh-form-enable-listener='false'])");

      for (let node of enabletargets)
      {
        if (node.propWhFormSavedEnabled === undefined)
          node.propWhFormSavedEnabled = !node.disabled && !("whFormDisabled" in node.dataset);

        // The field is enabled if all of these are matched:
        // - we're setting it to enabled now
        // - it hasn't been disabled explicitly (set initially on the node)
        // - it hasn't been disabled through enablecomponents
        let node_enabled = enabled && node.propWhFormSavedEnabled && (!node.name || !targets.has(node.name) || targets.get(node.name));

        if(node_enabled !== node.propWhNodeCurrentEnabled)
        {
          node.propWhNodeCurrentEnabled = node_enabled;

          // Give the formgroup a chance to handle it
          if (dompack.dispatchCustomEvent(node, "wh:form-enable", { bubbles: true, cancelable: true, detail: { enabled: node_enabled }}))
          {
            // Not cancelled, so run our default handler
            node.disabled = !node_enabled;
          }

          if (!node_enabled && !tovalidate.includes(node))
            tovalidate.push(node); // to clear errors for this disabled field
        }
      }

      let requiretargets = dompack.qSA(formgroup, "input, select, textarea");
      for (let node of requiretargets)
      {
        // Remove 'required' if originally disabled.
        if (node.propWhFormSavedRequired === undefined)
          node.propWhFormSavedRequired = !!node.required;

        let node_required = (node.propWhFormSavedRequired || required) && !node.disabled && visible;
        if(node.propWhNodeCurrentRequired !== node_required)
        {
          node.propWhNodeCurrentRequired = node_required;

          // Give the formgroup a chance to handle it
          if (dompack.dispatchCustomEvent(node, "wh:form-require", { bubbles: true, cancelable: true, detail: { required: node_required }}))
          {
            // Not cancelled, so run our default handler
            node.required = node_required;
          }

          if (!node_required && formgroup.classList.contains("wh-form__fieldgroup--error") && !tovalidate.includes(node))
            tovalidate.push(node); // to clear errors for this now optional field
        }
      }
    }

    if (tovalidate.length)
      await this.validate(tovalidate, { focusfailed: false });

    this.fixupMergeFields(mergeNodes);
  }

  async fixupMergeFields(nodes)
  {
    // Rename the data-wh-merge attribute to data-wh-dont-merge on hidden pages and within hidden formgroups to prevent
    // merging invisible nodes
    let formvalue = await this.getFormValue();
    for (let node of nodes)
    {
      if (node.propWhFormCurrentVisible)
      {
        for(let mergeNode of dompack.qSA(node, '*[data-wh-dont-merge]'))
        {
          mergeNode.dataset.whMerge = mergeNode.dataset.whDontMerge;
          delete mergeNode.dataset.whDontMerge;
        }
        merge.run(node, { form: formvalue });
      }
      else
      {
        for(let mergeNode of dompack.qSA(node, '*[data-wh-merge]'))
        {
          mergeNode.dataset.whDontMerge = mergeNode.dataset.whMerge;
          delete mergeNode.dataset.whMerge;
        }
      }
    }
  }

  _matchesCondition(conditiontext)
  {
    if(!conditiontext)
      return true;

    let condition = JSON.parse(conditiontext).c;
    return this._matchesConditionRecursive(condition);
  }

  _getVariableValue(fieldname)
  {
    if(this.node.dataset.whFormVariables)
    {
      let vars = JSON.parse(this.node.dataset.whFormVariables);
      if(fieldname in vars)
        return vars[fieldname];
    }

    let matchfield = this.elements[fieldname];
    if(!matchfield)
    {
      console.error(`No match for conditional required field '${fieldname}'`);
      return null;
    }

    // IE11 returns an HTMLCollection for checkbox/radio groups, so check for that instead of RadioNodeList (which is undefined in IE11)
    if (matchfield instanceof HTMLCollection
        || (typeof RadioNodeList != "undefined" && matchfield instanceof RadioNodeList))
    {
      let currentvalue = null;
      for (let field of matchfield)
        if (field.checked)
        {
          if (field.type != "checkbox")
            return field.value;

          currentvalue = [];
          currentvalue.push(field.value);
        }
      return currentvalue;
    }

    if (matchfield.type == "checkbox")
      return matchfield.checked ? [ matchfield.value ] : null;

    if (matchfield.type == "radio")
      return matchfield.checked ? matchfield.value : null;

    return matchfield.value;
  }

  _matchesConditionRecursive(condition)
  {
    if (condition.matchtype == "AND")
    {
      for (let subcondition of condition.conditions)
        if (!this._matchesConditionRecursive(subcondition))
          return false;
      return true;
    }
    else if (condition.matchtype == "OR")
    {
      for (let subcondition of condition.conditions)
        if (this._matchesConditionRecursive(subcondition))
          return true;
      return false;
    }
    else if (condition.matchtype == "NOT")
    {
      return !this._matchesConditionRecursive(condition.condition);
    }

    let currentvalue = this._getVariableValue(condition.field);

    if(condition.matchtype == "HASVALUE")
      return !!currentvalue == !!condition.value;

    if(condition.matchtype == "IN")
    {
      if (Array.isArray(currentvalue))
        return currentvalue.some(value => condition.value.includes(value));
      else
        return condition.value.includes(currentvalue);
    }

    return console.error(`No support for conditional type '${condition.matchtype}'`), false;
  }

  _updatePageNavigation()
  {
    let pagestate = this._getPageState();
    let nextpage = this._getDestinationPage(pagestate, +1);
    let morepages = nextpage != -1;
    let curpagerole = pagestate.pages[pagestate.curpage] ? pagestate.pages[pagestate.curpage].dataset.whFormPagerole : '';
    let nextpagerole = morepages ? pagestate.pages[nextpage].dataset.whFormPagerole : "";

    dompack.toggleClasses(this.node, { "wh-form--allowprevious": pagestate.curpage > 0 && curpagerole != 'thankyou'
                                     , "wh-form--allownext":     morepages && nextpagerole != 'thankyou'
                                     , "wh-form--allowsubmit":   curpagerole != 'thankyou' && (!morepages || nextpagerole == 'thankyou')
                                     });
  }

  _navigateToThankYou()
  {
    let state = this._getPageState();
    if(state.curpage >= 0)
    {
      let nextpage = this._getDestinationPage(state, +1);
      if (nextpage != -1 && state.pages[nextpage] && state.pages[nextpage].dataset.whFormPagerole == 'thankyou')
      {
        if (state.pages[nextpage].dataset.whFormPageredirect)
          executeSubmitInstruction({ type: "redirect", url: state.pages[nextpage].dataset.whFormPageredirect });
        else
          this.gotoPage(nextpage);
      }
    }
  }

  /* Override this to overwrite the processing of individual fields. Note that
     radio and checkboxes are not passed through getFieldValue, and that
     getFieldValue may return undefined or a promise. */
  async getFieldValue(field)
  {
    if(field.hasAttribute('data-wh-form-name') || field.whUseFormGetValue)
    {
      //create a deferred promise for the field to fulfill
      let deferred = dompack.createDeferred();
      //if cancelled, we'll assume the promise is taken over
      if(!dompack.dispatchCustomEvent(field, 'wh:form-getvalue', { bubbles:true, cancelable:true, detail: { deferred } }))
        return deferred.promise;
    }
    if(field.nodeName == 'INPUT' && field.type == 'file')
    {
      //FIXME multiple support
      if(field.files.length==0)
        return null;

      let dataurl = await compatupload.getFileAsDataURL(field.files[0]);
      return { filename: field.files[0].name.split('\\').join('/').split('/').pop() //ensure we get the last part
             , link: dataurl
             };
      // return Promise.all(Array.from(field.files).map(async function(fileobject)
      //          {
      //            let dataurl = await compatupload.getFileAsDataURL(fileobject);
      //            return { filename: fileobject.name.split('\\').join('/').split('/').pop() //ensure we get the last part
      //                   , dataurl: dataurl
      //                   };
      //          }));
    }
    return field.value;
  }

  /* Override this to overwrite the processing of radios and checkboxes. */
  getMultifieldValue(name, fields)
  {
    return fields.map(node => node.value);
  }

  /* Override this to overwrite the setting of individual fields. In contrast
     to getFieldValue, this function will also be invoked for radio and checkboxes */
  setFieldValue(fieldnode, value)
  {
    if(fieldnode.hasAttribute('data-wh-form-name'))
    {
      if (!dompack.dispatchCustomEvent(fieldnode, 'wh:form-setvalue', { bubbles:true, cancelable:true, detail: { value } }))
        return;
      // Event is not cancelled, set node value directly
    }
    if(dompack.matches(fieldnode, 'input[type=radio], input[type=checkbox]'))
    {
      fieldnode.checked = !!value;
      return;
    }
    dompack.changeValue(fieldnode, value);
  }

  _queryAllFields()
  {
    return Array.from(this.node.querySelectorAll(anyinputselector));
  }

  /** Return the names of all form elements */
  getFormElementNames()
  {
    let uniquenames=[];
    for (let node of this._queryAllFields())
    {
      if(node.name && !uniquenames.includes(node.name))
        uniquenames.push(node.name);
    }
    return uniquenames;
  }

  /** Return a promise resolving to the submittable form value */
  getFormValue(options)
  {
    return new Promise( (resolve,reject) =>
    {
      let outdata = {};
      let fieldpromises = [];

      let multifields = Array.from(this.node.querySelectorAll('input[type=radio], input[type=checkbox]'));
      for(let multifield of multifields)
      {
        if(!multifield.name || multifield.name in outdata)
          continue; //we did this one
        if (!this._isNowSettable(multifield))
          continue;

        let fields = multifields.filter(node => node.checked && node.name == multifield.name);
        this._processFieldValue(outdata, fieldpromises, multifield.name, this.getMultifieldValue(multifield.name, fields));
      }
      for(let field of this.node.querySelectorAll('input:not([type=radio]):not([type=checkbox]),select,textarea,*[data-wh-form-name]'))
      {
        let name = field.getAttribute("data-wh-form-name") || field.name;
        if(!name)
          continue;
        if (!this._isNowSettable(field))
          continue;

        if(name in outdata)
        {
          console.error("[fhv] Encountered duplicate field '" + name + "' while building getFormValue result", field);
          continue;
        }
        this._processFieldValue(outdata, fieldpromises, name, this.getFieldValue(field));
      }
      Promise.all(fieldpromises).then( () => resolve(outdata)).catch( e => reject(e));
    });
  }

  _isNowSettable(node)
  {
    // If the node is disabled, it's not settable
    if (node.disabled)
      return false;

    // If the node's field group is disabled or hidden, it's not settable
    let formgroup = dompack.closest(node, ".wh-form__fieldgroup");
    if (formgroup)
    {
      if (formgroup.classList.contains("wh-form__fieldgroup--disabled"))
        return false;
      if (formgroup.classList.contains("wh-form__fieldgroup--hidden"))
        return false;
    }

    // If the node's form page is hidden dynamically, it's not settable
    let formpage = dompack.closest(node, ".wh-form__page");
    if (formpage)
    {
      if (formpage.propWhFormCurrentVisible === false)
        return false;
    }
    // The node is settable
    return true;
  }

  _processFieldValue(outdata, fieldpromises, fieldname, receivedvalue)
  {
    if(receivedvalue === undefined)
      return;
    if(receivedvalue.then)
    {
      fieldpromises.push(new Promise( (resolve,reject) =>
      {
        receivedvalue.then( result =>
        {
          if(result !== undefined)
            outdata[fieldname] = result;

          resolve();
        }).catch(e => reject(e));
      }));
    }
    else
    {
      outdata[fieldname] = receivedvalue;
    }
  }

  //get the option lines associated with a specific radio/checkbox group
  getOptions(name)
  {
    let nodes = this.node.elements[name];
    if(!nodes)
      return [];
    if(nodes.length === undefined)
      nodes = [nodes];

    return Array.from(nodes).map(node => ({ inputnode: node
                                          , fieldline: dompack.closest(node, '.wh-form__fieldline')
                                          , value: node.value
                                          }));
  }

  /** gets the selected option associated with a radio/checkbox group as an array
      */
  getSelectedOptions(name)
  {
    let opts = this.getOptions(name);
    opts = opts.filter(node => node.inputnode.checked);
    return opts;
  }

  /** get the selected option associated with a radio/checkbox group. returns an object that's either null or the first selected option
      */
  getSelectedOption(name)
  {
    let opts = this.getSelectedOptions(name);
    return opts.length ? opts[0] : null;
  }

  /** get the fieldgroup for an element */
  getFieldGroup(name)
  {
    let node = this.node.elements[name];
    if(!node)
      return null;

    if(node.length !== undefined)
      node = node[0];

    return dompack.closest(node, '.wh-form__fieldgroup');
  }

  /** get the values of the currently selected radio/checkbox group */
  getValues(name)
  {
    return this.getSelectedOptions(name).map(node=>node.value);
  }
  /** get the value of the first currently selected radio/checkbox group */
  getValue(name)
  {
    let vals = this.getValues(name);
    return vals.length ? vals[0] : null;
  }

  setFieldError(field, error, options)
  {
    FormBase.setFieldError(field,error,options);
  }

  _getErrorForValidity(field,validity)
  {
    if(validity.customError && field.validationMessage)
      return field.validationMessage;

    if(validity.valueMissing)
      return getTid("publisher:site.forms.commonerrors.required");
    if(validity.rangeOverflow)
      return getTid("publisher:site.forms.commonerrors.max", field.max);
    if(validity.rangeUnderflow)
      return getTid("publisher:site.forms.commonerrors.min", field.min);
    if(validity.badInput)
      return getTid("publisher:site.forms.commonerrors.default");
    if(validity.typeMismatch)
      if(["email", "url", "number"].includes(field.type))
        return getTid("publisher:site.forms.commonerrors." + field.type);

    for(let key of ["badInput", "customError", "patternMismatch", "rangeOverflow", "rangeUnderflow", "stepMismatch", "tooLong", "tooShort", "typeMismatch", "valueMissing"])
      if(validity[key])
        return key;

    return '?';
  }

  async validateSingleFormField(field)
  {
    return true;
  }

  async _validateSingleFieldOurselves(field)
  {
    //browser checks go first, any additional checks are always additive (just disable browserchecks you don't want to apply)
    if(field.checkValidity)
    {
      let validitystatus = field.checkValidity();
      if(this._dovalidation)  //we're handling validation UI ourselves
      {
        //we need a separate prop for our errors, as we shouldn't clear explicit errors
        field.propWhValidationError = validitystatus ? '' : this._getErrorForValidity(field, field.validity);

        this._reportFieldValidity(field);
      }
      if(!validitystatus)
        return false;
    }

    let usercheckresult = await this.validateSingleFormField(field);
    if(!usercheckresult) //rejected
      return false;

    if(field.whFormsApiChecker && this._dovalidation)
    {
      field.whFormsApiChecker();
      if(!this._reportFieldValidity(field))
        return false;
    }
    return true;
  }

  /** validate the form
      @param limitset A single element, nodelist or array of elements to validate (or their children)
      @param options.focusfailed Focus the first invalid element (defaults to true)
      @return a promise that will fulfill when the form is validated
      @cell return.valid true if the fields successfuly validated  */
  async validate(limitset, options)
  {
    let tovalidate; //fields to validate
    if(!limitset) //no limit specified
    {
      tovalidate = this._queryAllFields();
    }
    else
    {
      tovalidate = [];
      let checklist = Array.isArray(limitset) ? limitset : [limitset];
      checklist.forEach(node =>
      {
        if(dompack.matches(node, anyinputselector))
          tovalidate.push(node);
        tovalidate = tovalidate.concat(Array.from(node.querySelectorAll(anyinputselector)));
      });

      //If we need to validate a radio, validate all radios in their group so we can properly clear their error classes
      tovalidate.filter(node => node.name && node.type == "radio").forEach(node =>
      {
        let siblings = dompack.qSA(this.node, `input[name="${node.name}"]`);
        tovalidate = tovalidate.concat(siblings.filter(sibling => !tovalidate.includes(sibling)));
      });
    }

    let lock = dompack.flagUIBusy();
    try
    {
      if(!tovalidate.length)
        return { valid: true, failed: [], firstfailed: null };

      let deferred = dompack.createDeferred();
      let result;
      let validationcancelled;

      if(dompack.dispatchCustomEvent(this.node, 'wh:form-validate', { bubbles:true, cancelable:true, detail: { tovalidate: tovalidate, deferred: deferred } }))
      {
        //not cancelled, carry out the validation ourselves.
        let validationresults = await Promise.all(tovalidate.map(fld => this._validateSingleFieldOurselves(fld)));
        //remove the elements from validate for which the promise failed
        let failed = tovalidate.filter( (fld,idx) => !validationresults[idx]);
        result = { valid: failed.length == 0
                 , failed: failed
                 };
      }
      else
      {
        validationcancelled = true;
        result = await deferred.promise; //then we expect the validator to sort it all out
      }

      result.firstfailed = result.failed.length ? result.failed[0] : null;
      if(result.firstfailed && (!options || options.focusfailed))
      {
        dompack.focus(result.firstfailed);
        if(!this._dovalidation && !validationcancelled)
          reportValidity(result.firstfailed);
      }

      if(dompack.debugflags.fhv)
        console.log(`[fhv] Validation of ${tovalidate.length} fields done, ${result.failed.length} failed`, result);

      return result;
    }
    finally
    {
      lock.release();
    }
  }

  reset()
  {
    this.node.reset();
  }

}

FormBase.getForNode = function(node)
{
  return node.propWhFormhandler || null;
};

FormBase.setFieldError = setFieldError;
FormBase.setupValidator = setupValidator;
