import * as dompack from "dompack";
import { getTid } from "@mod-tollium/js/gettid";

export default class AddressField
{
  constructor(node, options)
  {
    this.node = node;
    this.form = dompack.closest(this.node,"form").propWhFormhandler;

    // AddressField is initialized for the address's country field, so first find the other fields
    this.countryNode = dompack.qS(this.node, "select.wh-form__pulldown");
    if (!this.countryNode)
      throw new Error("Could not find country select node");

    this.fieldName = this.countryNode.name.substr(0, this.countryNode.name.lastIndexOf("."));
    this.orderingData = this.countryNode.dataset.orderingdata && JSON.parse(this.countryNode.dataset.orderingdata);
    let prefixLength = this.fieldName.length + 1; // fieldName + "."
    this.allFields = new Map();
    this.allFields.set(this.countryNode.name.substr(prefixLength),
        { node: this.countryNode
        , fieldgroup: dompack.closest(this.countryNode, ".wh-form__fieldgroup")
        });
    for (let field of dompack.qSA(dompack.closest(this.node, "form"), `[name^='${this.fieldName}.']`))
    {
      this.allFields.set(field.name.substr(prefixLength),
          { node: field
          , fieldgroup: dompack.closest(field, ".wh-form__fieldgroup")
          });
      field.addEventListener("change", event => this._gotFieldChange(event));
    }

    if (this.orderingData)
    {
      this.countryNode.addEventListener("change", () => this._reconfigureFieldOrdering());
      this._reconfigureFieldOrdering();
    }
  }

  _gotFieldChange(event)
  {
    if (this._getFieldValue("country") === "NL")
    {
      if (!this._getFieldValue("zip") || !this._getFieldValue("nr_detail"))
        return;
    }
    this._checkValidity(event);
  }

  _getFieldValue(fieldname)
  {
    const data = this.allFields.get(fieldname);
    if (data)
      return data.node.value;
    return "";
  }

  _reconfigureFieldOrdering()
  {
    const country = this.countryNode.value;
    if (country)
    {
      const ordering = this.orderingData.find(e => e.countries.length === 0 || e.countries.includes(country));
      if (ordering)
      {
        console.warn(ordering.fieldorder);
        let prevgroup;
        for (let idx = 0; idx < ordering.fieldorder.length; ++idx)
        {
          const fieldgroup = this.allFields.get(ordering.fieldorder[idx]).fieldgroup;
          if (idx !== 0)
          {
            const compareres = prevgroup.compareDocumentPosition(fieldgroup);
            if (compareres & Node.DOCUMENT_POSITION_PRECEDING)
            {
              console.log('switch');
              prevgroup.parentNode.insertBefore(fieldgroup, prevgroup.nextSibling);
            }
          }
          prevgroup = fieldgroup;
        }
      }
    }
  }

  async _checkValidity(event)
  {
    // Clear fields that are no longer visible after a country change, add visible fields to the value we're checking
    let value = {};
    this.allFields.forEach((field, key) =>
    {
      if (field.fieldgroup.classList.contains("wh-form__fieldgroup--hidden"))
        field.node.value = "";
      else
        value[key] = field.node.value;
    });

    let result;
    try
    {
      dompack.dispatchCustomEvent(this.node, "wh:form-busyfield", { bubbles: true, cancelable: false, detail: { field: this, isbusy: true }});
      result = await this.form.invokeBackgroundRPC(this.fieldName + ".ValidateValue", value);
      dompack.dispatchCustomEvent(this.node, "wh:form-busyfield", { bubbles: true, cancelable: false, detail: { field: this, isbusy: false }});
    }
    catch (e)
    {
      console.error(`Error while validating value: ${e}`);
      return;
    }

    this.allFields.forEach(field => this.form.setFieldError(field.node, "", { reportimmediately: true }));
    switch (result.status)
    {
      case "not_supported": // Address lookup not supported, treat as "ok"
      case "ok":
      {
        break;
      }
      case "not_enough_data":
      {
        // Nothing to check yet
        break;
      }
      case "invalid_zip":
      {
        this.form.setFieldError(this.allFields.get("zip").node, getTid("publisher:site.forms.addressfield.invalid_zip"), { reportimmediately: true });
        break;
      }
      case "invalid_nr_detail":
      {
        this.form.setFieldError(this.allFields.get("nr_detail").node, getTid("publisher:site.forms.addressfield.invalid_nr_detail"), { reportimmediately: true });
        break;
      }
      case "zip_not_found":
      {
        this.form.setFieldError(this.allFields.get("zip").node, getTid("publisher:site.forms.addressfield.zip_not_found"), { reportimmediately: true });
        break;
      }
      case "address_not_found":
      {
        this.form.setFieldError(this.allFields.get("street").node, getTid("publisher:site.forms.addressfield.address_not_found"), { reportimmediately: true });
        break;
      }
      case "different_citystreet": // This can happen when fields have been set for another country, we'll update those fields with correct values
      case "incomplete":
      {
        this.allFields.forEach((field, key) =>
        {
          if (key in result.looked_up)
            field.node.value = result.looked_up[key];
        });
        break;
      }
      case "lookup_failed":
      {
        console.error("Lookup failed, is the service configured correctly?");
        break;
      }
      default:
      {
        console.error(`Unknown status code '${result.status}' returned`);
        break;
      }
    }
  }
}
