//require("./index.scss");
require("./web/css/main.css");
//require("../wd001/web/js/main.js");
require("./web/js/main.js");

import * as dompack from 'dompack';

import * as wrdauth from '@mod-wrd/js/auth';

import "@webhare-system/wh/integration"; //we're a WebHare site
import "@webhare-publisher/richcontent/all";

//At minimum, activates required CSS and JSON/RPC links
import * as forms from '@mod-publisher/js/forms';

import { RPCFormBase, registerHandler } from "@mod-publisher/js/forms";

//Optionally: replaces upload fields with a nicer and edit-supporting version
import UploadField from '@mod-publisher/js/forms/fields/upload';
dompack.register(".wh-form__uploadfield", node => new UploadField(node));

//Enable the imgedit and/or rtd fields:
import ImgEditField from '@mod-publisher/js/forms/fields/imgedit';
dompack.register(".wh-form__imgedit", node => new ImgEditField(node));
import RTDField from '@mod-publisher/js/forms/fields/rtd';
dompack.register(".wh-form__rtd", node => new RTDField(node, { hidebuttons: ['table'] }));

forms.setup({ validate: true });

if (!window.console) {
  window.console = function() {};
}

console.log(wrdauth.getDefaultAuth());
console.log(wrdauth.getDefaultAuth().getUserInfo());

var EventServerConnection = require('@mod-system/js/net/eventserver');

//require('./index.scss');
//alert("hello2");
function cleanupEmptyParagraphs(pnode)
{//cleanup empty paragraphs between fullwidth blocks
  for(let node of pnode.querySelectorAll(".contents--fullwidth"))
  {
    let prevnode = node.previousElementSibling;
    if(prevnode && prevnode.nodeName == "P" && (prevnode.innerHTML == "" || prevnode.innerHTML == "<br>") )
      node.parentNode.removeChild(prevnode);
  }
}

dompack.register("article", node => cleanupEmptyParagraphs(node) );

document.addEventListener("DOMContentLoaded",function()
{
  //alert("DOMContentLoaded");
}); //DOMContentLoaded

function onBroadcastData(_event)
{
  console.log("onBroadcastData");
  console.log(_event);
}

var eventsconnection = null;
eventsconnection = new EventServerConnection({ url: "/wh_events/", log: true });
//eventsconnection = new EventServerConnection({ url: "https://webhare.local.wevolve.nl/wh_events/"});
//eventsconnection.on("data", event => onBroadcastData(event));
eventsconnection.on("data", function(_event) {
  console.log("event data:");
  console.log(_event);
  document.dispatchEvent(new CustomEvent("refreshclubtalk"));
});
eventsconnection.setGroups(["clubtalk"]);
eventsconnection.start();

class RTDForm extends RPCFormBase
{
  constructor(node)
  {
    super(node);
    //node.querySelector(".prefillbutton").addEventListener("click", () => this.doPrefill());
    this.doPrefill();
  }
  //onSubmit(_data)
  //{
  //  console.log(_data);
  //  this.submit();
  //}
  onSubmitFailed(_errors, _result)
  {
    //console.log(_errors); // string array
    //console.log(_result);
  }
  onSubmitSuccess(result)
  {
    //console.log(JSON.stringify(result));
    //qS("#rtdformresponse").textContent = JSON.stringify(result);
  }
  async doPrefill()
  {
    //console.log("doPrefill");
    let prefilled = await this.invokeRPC("PrefillRTD");
    //console.log(prefilled);
    //this.elements.rtdpersonprofile.value = prefilled.personprofile;
    //dompack.qS("#rtdPersonProfile").textContent = prefilled.personprofile;
    //console.log(this.elements.rtdPersonProfile);
  }
}

registerHandler("clubcontext:rtdform", node => new RTDForm(node));
//PersonProfileForm.invokeRPC('prefillrtd');
//let form = dompack.qS("#rtdform");
//form.doPrefill();

class ClubtalkRTDForm extends RPCFormBase
{
  constructor(node)
  {
    super(node);
    //node.querySelector(".prefillbutton").addEventListener("click", () => this.doPrefill());
    //this.doPrefill();
    this.pvt_node = node;
    //console.log(node);
    //console.log(node.querySelector("#clubtalkrtdform-rtdClubtalk"));
    let rtdnode = node.querySelector("#clubtalkrtdform-rtdClubtalk");
    //console.log(rtdnode.whRTDField);
    //rtdnode.whRTDField.rte.setValue("empty");
    let formbuttonsnode = dompack.qS(".wh-form__navbuttons");
    let newbutton = dompack.create("button", { className: "wh-form__button cancel-react", textContent: "Annuleren" });
    newbutton.addEventListener('click', event => {
      event.preventDefault();
      //console.log("cancel react");
      dompack.qS("p.react-to").textContent = "";
      dompack.qS("p.react-to").classList.toggle("nodisplay", true);
      dompack.qS("#texteditReactToId").value = "";
      //dompack.qS("button.cancel-react").classList.toggle("nodisplay", true);
      dompack.qS("button.wh-form__button--submit").textContent = "Versturen";
      dompack.qS(".clubtalk-react").classList.toggle("nodisplay", true);
      dompack.qS(".button.add-item").classList.toggle("nodisplay", false);
      dompack.qS(".clubtalkwrapper").classList.toggle("form-open", false);
    });
    dompack.append(formbuttonsnode, newbutton);
  }
  onSubmit(_data)
  {
    console.log(_data);
    //this.submit();
  }
  onSubmitFailed(_errors, _result)
  {
    console.log("onSubmitFailed");
    console.log(JSON.stringify(_errors));
    console.log(JSON.stringify(_result));
    this.reset();
    //console.log(_errors); // string array
    //console.log(_result);
  }
  onSubmitSuccess(result)
  {
    console.log("onSubmitSuccess");
    console.log(JSON.stringify(result));
    let rtdnode = this.pvt_node.querySelector("#clubtalkrtdform-rtdClubtalk");
    console.log("node:");
    console.log(rtdnode.whRTDField);
    rtdnode.whRTDField.rte.setValue("");
    //abort;
    console.log("pvt_node:");
    console.log(this.pvt_node);
    console.log("dompack:");
    console.log(dompack.qS("#clubtalkrtdform-rtdClubtalk"));
    console.log(dompack.qS("#clubtalkrtdform"));

    dompack.qS("p.react-to").textContent = "";
    dompack.qS("p.react-to").classList.toggle("nodisplay", true);
    dompack.qS("#texteditReactToId").value = "";
    //dompack.qS("button.cancel-react").classList.toggle("nodisplay", true);
    dompack.qS("button.wh-form__button--submit").textContent = "Versturen";

    dompack.qS(".clubtalk-react").classList.toggle("nodisplay", true);
    dompack.qS(".button.add-item").classList.toggle("nodisplay", false);
    dompack.qS(".clubtalkwrapper").classList.toggle("form-open", false);
  }
  async doPrefill()
  {
    //console.log("doPrefill");
    //let prefilled = await this.invokeRPC("PrefillRTD");
    //console.log(prefilled);
    //this.elements.rtdpersonprofile.value = prefilled.personprofile;
    //dompack.qS("#rtdPersonProfile").textContent = prefilled.personprofile;
    //console.log(this.elements.rtdPersonProfile);
  }
}

registerHandler("clubcontext:clubtalkrtdform", node => new ClubtalkRTDForm(node));

/*
dompack.register(".button.react", (_node, _idx) =>
{
  _node.addEventListener('click', event =>
  {
    event.preventDefault();
    console.log("clicked");
    let linode = _node.closest("li");
    console.log(linode);
    console.log(linode.getAttribute("data-id"));
    dompack.qS("p.react-to").textContent = "Reageren op " + linode.getAttribute("data-fullname");
    dompack.qS("p.react-to").classList.toggle("nodisplay", false);
    dompack.qS("#texteditReactToId").value = linode.getAttribute("data-id");
    dompack.qS("button.cancel-react").classList.toggle("nodisplay", false);
    dompack.qS("button.wh-form__button--submit").textContent = "Reageren";
    dompack.qS(".clubtalk-react").classList.toggle("nodisplay", false);
    dompack.qS(".button.add-item").classList.toggle("nodisplay", true);
  });
});
*/

