/* @import: import '@mod-publisher/js/forms';
*/
import * as dompack from 'dompack';
import './internal/requiredstyles.css';
import FormBase from './formbase.es';
import RPCFormBase from './rpc.es';
export { FormBase, RPCFormBase };
export { setFieldError, setupValidator } from './internal/customvalidation.es';

let handlers = { "publisher:form": FormBase, "publisher:rpc": RPCFormBase };
let didregister;
let validatesettings = null;
let defaultsettings = { 'triggerevents': ['focusout']
                      , 'triggerafterfailure': ['input','change']
                      };

export function registerHandler(handlername, handler)
{
  handlers[handlername] = handler;
  if(didregister) //then we need to catch up registrations
    for(let form of dompack.qSA('form[data-wh-form-handler]'))
    {
      if(form.dataset.whFormHandler == handlername)
      {
        let newform = new handler(form);
        if(validatesettings)
          newform.setupValidation(validatesettings);
      }
    }
}

export function setup(options)
{
  validatesettings = options.validate === true ? defaultsettings
                     : !options.validate ? null : {...defaultsettings, ...options.validate};

  for(let form of dompack.qSA('form[data-wh-form-handler]'))
    if(form.propWhFormhandler)
      form.propWhFormhandler.setupValidation(validatesettings);
}

dompack.register("form[data-wh-form-handler]", function(form)
{
  //ADDME allow late registration of handlers, delay/block form submission until we have the handler
  didregister=true;
  if(handlers[form.dataset.whFormHandler] && !form.propWhFormhandler)
  {
    let formobj = new handlers[form.dataset.whFormHandler](form);
    if(validatesettings)
      formobj.setupValidation(validatesettings);
  }
});
