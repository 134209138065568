// Auto-generated RPC interface from /opt/wh/whtree/modules/publisher/js/forms/internal/form.rpc.json
var JSONRPC = require("@mod-system/js/net/jsonrpc");
var request = exports.rpcObject = new JSONRPC();
exports.rpcResolve = function(promise, result) { request._doAsyncAbort(promise, result) };
exports.rpcReject = function(promise, reject) { request._doAsyncAbort(promise, null, reject) };
var urlappend = '';
if(self&&self.location)
{
  var urldebugvar = window.location.href.match(new RegExp('[?&#]wh-debug=([^&#?]*)'));
  if(urldebugvar)
    urlappend='?wh-debug='+urldebugvar[1];
}
var options = exports.rpcOptions = {};

Object.defineProperty(module.exports, "HTTP_ERROR", { get: function() { return JSONRPC.HTTP_ERROR; }});
Object.defineProperty(module.exports, "JSON_ERROR", { get: function() { return JSONRPC.JSON_ERROR; }});
Object.defineProperty(module.exports, "PROTOCOL_ERROR", { get: function() { return JSONRPC.PROTOCOL_ERROR; }});
Object.defineProperty(module.exports, "RPC_ERROR", { get: function() { return JSONRPC.RPC_ERROR; }});
Object.defineProperty(module.exports, "OFFLINE_ERROR", { get: function() { return JSONRPC.OFFLINE_ERROR; }});
Object.defineProperty(module.exports, "TIMEOUT_ERROR", { get: function() { return JSONRPC.TIMEOUT_ERROR; }});
Object.defineProperty(module.exports, "SERVER_ERROR", { get: function() { return JSONRPC.SERVER_ERROR; }});

// Adding dependency: '/opt/webhare/modules/publisher/lib/internal/forms/rpc.whlib'

exports.getUploadedFileFinalURL = exports.GetUploadedFileFinalURL = /*STRING*/function(/*STRING*/ uploadurl)
{
var opts={url:"/wh_services/publisher/forms"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("GetUploadedFileFinalURL",Array.prototype.slice.call(arguments),opts);
}

exports.getImgFromRemoteURL = exports.GetImgFromRemoteURL = /*STRING*/function(/*STRING*/ uploadurl)
{
var opts={url:"/wh_services/publisher/forms"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("GetImgFromRemoteURL",Array.prototype.slice.call(arguments),opts);
}

exports.requestBuiltinForm = exports.RequestBuiltinForm = /*RECORD*/function(/*RECORD*/ submitinfo, /*STRING*/ filename, /*STRING*/ formname)
{
var opts={url:"/wh_services/publisher/forms"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("RequestBuiltinForm",Array.prototype.slice.call(arguments),opts);
}

exports.validateEmbeddedObjects = exports.ValidateEmbeddedObjects = /*RECORD*/function(/*STRING ARRAY*/ objrefs)
{
var opts={url:"/wh_services/publisher/forms"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("ValidateEmbeddedObjects",Array.prototype.slice.call(arguments),opts);
}

exports.callFormService = exports.CallFormService = /*VARIANT*/function(/*STRING*/ method, /*VARIANT ARRAY*/ args)
{
var opts={url:"/wh_services/publisher/forms"+urlappend};
for (var k in options)
if(options.hasOwnProperty(k))
opts[k]=options[k];

return request.promiseRequest("CallFormService",Array.prototype.slice.call(arguments),opts);
}
