//ADDME eventually move this to dompack, once the APIs are sufficiently stable - or we need it outside forms

import * as dompack from 'dompack';

// Function added to log current node, shortens code in run
function constructMergeError(node, errortext)
{
  console.error(`Merge error: ${errortext}, node:`, node);
  return new Error(errortext);
}

export async function run(node, data)
{
  for(let node of dompack.qSA(node, '*[data-wh-merge]'))
  {
    // Parse 'a=b;c=d(e)'
    let sets = node.dataset.whMerge.split(";");
    for (let set of sets)
    {
      let parts = set.split(":");
      if (parts.length !== 2)
        throw constructMergeError(node, `Illegal merge expression: '${set}'`);

      let prop = parts[0].trim();
      let exprpath = parts[1].trim();

      let func;
      let callparts = exprpath.split("(");
      if (callparts.length > 1)
      {
        if (callparts.length !== 2)
          throw constructMergeError(node, `Illegal merge value: '${set}'`);

        func = callparts[0].trim();
        let funcrest = callparts[1].split(")");
        if (funcrest.length !== 2 || funcrest[1] !== "")
          throw constructMergeError(node, `Illegal merge value: '${set}'`);

        exprpath = funcrest[0].trim();
      }

      let value = data;
      let exprpathparts = exprpath.split(".");
      for (let i = 0; i < exprpathparts.length; ++i)
      {
        value = value[exprpathparts[i].trim()];
        if (typeof value === "undefined")
        {
          let partialpath = exprpathparts.slice(0, i + 1).join(".");
          throw constructMergeError(node, `Could not find merge value '${partialpath}' in data`);
        }
      }

      if (func)
        throw constructMergeError(node, `Unknown formatting function ${func}`);

      if (typeof value !== "string" && typeof value !== "number")
        throw constructMergeError(node, `Got a value of `);

      switch (prop)
      {
        case "textContent":   node.textContent = value; break;
        default:              throw constructMergeError(node, `Cannot modify '${prop}' with merge`);
      }
    }
  }
}
