import * as dompack from 'dompack';
import { getTid } from "@mod-tollium/js/gettid";
import "./form.lang.json";

//show HTML5-style validity errors
export function reportValidity(node)
{
  if(node.reportValidity) //not present on all browsers, clicking a submit is a workaround
  {
    node.reportValidity();
    return true;
  }
  let form = dompack.closest(node,'form');
  if(!form)
    return false;

  let submitbutton = form.querySelector("button[type=submit], input[type=submit]" );
  if(!submitbutton)
    return false;

  submitbutton.click();
  return true;
}


function setupServerErrorClear(field)
{
  var cleanupfunction = () =>
  {
    window.removeEventListener("change",cleanupfunction, true);
    window.removeEventListener("input",cleanupfunction, true);
    window.removeEventListener("blur",cleanupfunction, true);
    setFieldError(field, '', {serverside:true});
  };

  // to be rightly paranoid (plugins and JS directly editing other fields) we'll blur when anything anywhere seems to change
  // eg wrd.testwrdauth-emailchange would fail on Chrome without this if the browser window was not currently focused
  window.addEventListener("change", cleanupfunction, true);
  window.addEventListener("input", cleanupfunction, true);
  window.addEventListener("blur", cleanupfunction, true);
}


export function setFieldError(field, error, options) //as we support parsley and possible future alternatives... we'll do this with events for now
{
  if(dompack.debugflags.fhv)
    console.log(`[fhv] ${error?"Setting":"Clearing"} error for field ${field.name}`, field, error, options);

  options = { serverside: false, reportimmediately: false, ...options };
  field.propWhSetFieldError = error;
  field.propWhErrorServerSide = options.serverside;

  if(error && options.serverside) //we need to reset the check when the user changed something
    setupServerErrorClear(field);

  //if the error is being cleared, reset any html5 validity stuff to clear custom errors set before wh:form-setfielderror was intercepted
  if(!error && field.setCustomValidity)
    field.setCustomValidity("");

  if(!dompack.dispatchCustomEvent(field, 'wh:form-setfielderror', //this is where parsley hooks in and cancels to handle the rendering of faults itself
          { bubbles:true
          , cancelable:true
          , detail: { error: error
                    , reportimmediately: options.reportimmediately
                    , serverside: options.serverside
                    , metadata: options.metadata
                    } }))
  {
    return;
  }

  //fallback to HTML5 validation
  if(field.setCustomValidity)
  {
    if(typeof error == "object") //we got a DOM?
      error = error.textContent || getTid("publisher:site.forms.commonerrors.default"); //we don't want to suddenly change from 'we had an error' to 'no error'

    field.setCustomValidity(error || "");
    if(!options.reportimmediately || reportValidity(field))
      return;
  }
  if(error) //if we're not setting an error, it's not an issue that we can't show one
    throw new Error("No handler available to process setFieldError request");
}

export function setupValidator(node, checker)
{
  var check = () =>
  {
    let error = checker(node);
    if(dompack.debugflags.fhv)
      console.log(`[fhv] Custom check ${error ? `setting error '${error}'` : 'clearing error'} for `,node);

    //FIXME shouldn't we set propWhValidationError instead ?
    setFieldError(node, error, { reportimmediately: false });
  };
  node.addEventListener("blur", check);
  node.addEventListener("input", check);
  node.whFormsApiChecker = check;
  check();
}
